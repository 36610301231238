import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import query from "./query.js";
import Link from "components/link";
import { ReactComponent as Arrow } from "../../assets/big_arrow.svg";
import styles from "./alertBar.module.scss";

import { AppContext } from "appContext";


const AlertBar = ({ location }) => {
  const [visible, setVisible] = useState(false)
  const { loading, error, data } = useQuery(query);
  const navContext = useContext(AppContext);
  let homepage = location === "/"

  useEffect(() => {
    const { isEnabled, homepageOnly } = data ? data.alertBar : {};
    navContext.setAlertbar(isEnabled);
    setVisible((isEnabled && homepage && homepageOnly) || (isEnabled && !homepageOnly) ? true : false)
  }, [data, homepage, navContext])

  if (loading || error) {
    return null;
  }

  const { plainText, cta } = data ? data.alertBar : {};

  return (
    visible &&
    <div className={styles.alertBar}>
      <div className={styles.alertBarText}>{plainText}</div>
      <Link className={styles.alertBarLink} {...cta}>
        <span>{cta.text}</span>
        <Arrow />
      </Link>
    </div>
  );
}

export default AlertBar