import React, { useContext, useEffect } from "react";
import Blocks from "blocks";
import usePage from "./usePage";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";

import { AppContext } from "appContext";
import Seo from "components/seo";

const Page = ({ match }) => {
  const { loading, error, data } = usePage({ uri: match.url.replace("/", "") });
  const navigationContext = useContext(AppContext);

  useEffect(() => {
    navigationContext.setIsReport(false);
  }, [data, navigationContext]);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    console.log(error)
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  switch (data.entry.typeHandle) {
    case "page":
      return (
        <React.Fragment>
          <Seo seomaticData={data.seomatic} />
          <Blocks blocks={data.entry.pageBuilder} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

export default Page;
