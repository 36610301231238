import { gql } from "apollo-boost";

export default gql`
query {
  alertBar: globalSet (handle:"alertBar") {
    ...on alertBar_GlobalSet {
      plainText
      homepageOnly
      isEnabled
      cta {
        text
        url
        type
        element {
          uri
        }
      }
    }
  }
}
`;
