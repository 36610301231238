import React from "react";
import Link from "components/link";
import { ReactComponent as Bubble } from '../../../src/assets/bubble.svg';
// import { AppContext } from "appContext";

import "./nav-block-matrix.scss";

const NavBlockMatrix = ({ navBlockMatrix }) => {
  return (
    <div className="navMatrixCards">
      <div className="navMatrixGrid">
        {navBlockMatrix.map(({ id, cardDescription, pageLink }) => {
          return (
            <div key={id} className="navCardContainer">
              <Link className="navLinkContainer" {...pageLink}>
                <div className={`icon ${pageLink.element && pageLink.element.typeHandle}`}>
                  <Bubble />
                </div>
                <div className="navCardContent">
                  <h1>{pageLink.text}</h1>
                  <p>{cardDescription}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NavBlockMatrix