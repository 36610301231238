import React from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const Seo = ({ seomaticData }) => {
  return (
    <Helmet>
      {ReactHtmlParser(seomaticData.metaTitleContainer)}
      {ReactHtmlParser(seomaticData.metaTagContainer)}
    </Helmet>
  );
}

export default Seo
