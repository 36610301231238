import { gql } from "apollo-boost";
import { seomatic } from "querypieces";

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
        ... on contact_contact_Entry {
            id
            heading
            introBody
            contactBlock {
              ... on contactBlock_conactBlock_BlockType {
                id
                body
                headline
                contactName
                emailAddress {
                  url
                  text
                }
                phoneNumber {
                  url
                  text
                }
              }
            }
        }
    }
    ${seomatic}
  }
`;
