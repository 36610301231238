import React from "react";
import Button from "components/button";
import RichText from "components/richText";

import "./header.scss";

const Header = ({
  breadcrumb,
  heading,
  subheader,
  introBody,
  richText,
  cta,
  isReport = false
}) => {
  let borderClass = (richText || introBody || subheader) ? "borderBottom" : ""
  return (
    <header className={`headerWrapper ${isReport ? 'specialReportHeader' : ''} ${borderClass}`}>
      {breadcrumb ? <p className="headerBreadcrumb">{breadcrumb}</p> : ""}
      {heading ? <h1 className="headerHeading">{heading}</h1> : ""}
      {subheader && <h2 className={"headerSubhead"}>{subheader}</h2>}
      {introBody ? <p className="headerIntroBody">{introBody}</p> : ""}
      {richText ?
        <div className="headerBody">
          <RichText
            richText={richText}
            textColor="blue"
          />
        </div>
        : null}
      {(cta.url || cta.element) ?
        <Button
          theme="blue"
          primary={!isReport}
          className="headerCTA"
          {...cta}
        >
        </Button> : null}
    </header>
  );
}

export default Header