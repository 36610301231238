import { gql } from "apollo-boost";
import { featurePostLinkTo, featurePostImage, seomatic } from "querypieces";

export default gql`
    query($uri: String) {
        entry(uri: [$uri]) {
            ... on news_news_Entry {
                id
                title
                heading
                featurePost {
                    ... on featurePost_featurePost_BlockType {
                        id
                        altText
                        plainText
                        themePicker
                        ${featurePostImage}
                        ${featurePostLinkTo}
                    }
                }
                articleFilterSelector {
                    ... on articleFilterSelector_newsFilterSection_BlockType {
                        id
                        plainText
                        children {
                            ... on articleFilterSelector_filterOption_BlockType {
                                id
                                articleType
                            }
                        }
                    }
                }
            }
        }
        ${seomatic}
    }
`;

