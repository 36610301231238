import { idtype } from "querypieces";

export default `
... on pageBuilder_relatedArticles_BlockType {
	${idtype}
	id
	subheader
	body
	tags {
	  id
	  ... on tags_Category {
		id
		title
	  }
	}
  }
`







// query MyQuery {
//   entries(relatedTo: 828, section: "blog") {
//     id
//     ... on blog_blog_Entry {
//       id
//       blogPostHeaderMatrix {
//         ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
//           id
//           header
//           publisher
//           date
//           authors
//         }
//       }
//       uri
//     }
//     ... on blog_externalPost_Entry {
//       id
//       blogPostHeaderMatrix {
//         ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
//           id
//           date
//           authors
//           header
//           publisher
//         }
//       }
//       relatedArticleImage {
//         ... on images_Asset {
//           id
//           optimizedRelatedArticleImage {
//             originalImageWidth
//             originalImageHeight
//             placeholder
//             placeholderSvg
//             lightness
//             placeholderWidth
//             placeholderHeight
//             maxSrcsetWidth
//             placeholderImage
//             placeholderSilhouette
//             src
//             srcWebp
//             srcset
//             srcsetWebp
//             placeholderBox
//           }
//         }
//       }
//     }
//   }
// }
