import React, { useState, createContext } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // const [hasHero, setHasHero] = useState(true);
  const [alertbar, setAlertbar] = useState(false);
  const [isReport, setIsReport] = useState(false);

  return (
    <AppContext.Provider
      value={{
        // setHasHero,
        // hasHero,
        alertbar,
        setAlertbar,
        setIsReport,
        isReport,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};