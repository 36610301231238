import { idtype } from "querypieces";

import halfPhotoHalfText from "../half-photo-half-text/query";
import navBlockMatrix from "../nav-block-matrix/query";
import featurePost from "../feature-post/query";
import fullPagePhotoMatrix from "../full-page-photo-matrix/query";
import relatedArticles from "../related-articles/query";
import collapsableAccordionMatrix from "../collapsable-accordion-matrix/query";
import leadershipTeam from "../leadership-team/query";
import inlinePhotoMatrix from "../inline-photo-matrix/query";
import outcomesMatrix from "../outcomes-matrix/query";
import bodyTextAndContactBlocks from "../body-text-and-contact-blocks/query";
import featureCampaign from "../feature-campaign/query";
import featureReport from "../feature-report/query";
import copyBlock from "../copyBlock/query";
import embedBlock from "blocks/embed-block/query";


export default `
...on pageBuilder_backgroundBlock_BlockType {
  ${idtype}
  colorSelect
  children {
    ${halfPhotoHalfText}
    ${navBlockMatrix}
    ${featurePost}
    ${fullPagePhotoMatrix}
    ${relatedArticles}
    ${collapsableAccordionMatrix}
    ${leadershipTeam}
    ${inlinePhotoMatrix}
    ${outcomesMatrix}
    ${bodyTextAndContactBlocks}
    ${featureCampaign}
    ${featureReport}
    ${copyBlock}
    ${embedBlock}
  }
}
`