import { gql } from "apollo-boost";
import { idtype, heroImg, fullPageImage, inlineImage } from 'querypieces';


export default gql`
    query($uri: String) {
        entry(uri: [$uri]) {
            ${idtype}
            uri
            ... on reports_reports_Entry {
                id
                title
                ${heroImg}
                heroHeading: plainText
                breadcrumb
                heading
                introBody
                body
                richText
                cta {
                  title
                  text
                  type
                  url
                  element {
                    uri
                  }
                }
                children {
                    ... on reports_chapter_Entry {
                        id
                        uri
                        url
                        title
                        slug
                        reportBuilder {
                            ... on reportBuilder_fullPagePhotoMatrix_BlockType {
                                ${idtype}
                                id
                                themePicker
                                fullPagePhotoMatrix {
                                  ... on fullPagePhotoMatrix_fullPagePhoto_BlockType {
                                    id
                                    altText
                                    photoBorderSwitch
                                    ${fullPageImage}
                                  }
                                  ... on fullPagePhotoMatrix_header_BlockType {
                                    id
                                    header
                                  }
                                  ... on fullPagePhotoMatrix_subheader_BlockType {
                                    id
                                    subheader
                                  }
                                  ... on fullPagePhotoMatrix_introBody_BlockType {
                                    id
                                    introBody
                                  }
                                  ... on fullPagePhotoMatrix_body_BlockType {
                                    id
                                    body
                                  }
                                  ... on fullPagePhotoMatrix_cta_BlockType {
                                    id
                                    cta {
                                        text
                                        type
                                        url
                                        element {
                                          uri
                                        }
                                    }
                                  }
                                }
                            }
                            ... on reportBuilder_inlinePhotoMatrix_BlockType {
                                ${idtype}
                                inlinePhotoMatrix {
                                  ... on inlinePhotoMatrix_inlinePhoto_BlockType {
                                    id
                                    altText
                                    photoBorderSwitch
                                    ${inlineImage}
                                  }
                                  ... on inlinePhotoMatrix_bigHeader_BlockType {
                                    id
                                    header
                                  }
                                  ... on inlinePhotoMatrix_bodyCopy_BlockType {
                                    id
                                    body
                                  }
                                  ... on inlinePhotoMatrix_subheader_BlockType {
                                    id
                                    subheader
                                  }
                                  ... on inlinePhotoMatrix_cta_BlockType {
                                    id
                                    cta {
                                        title
                                        text
                                        type
                                        url
                                        element {
                                            uri
                                        }
                                    }
                                  }
                                  ... on inlinePhotoMatrix_contactDownloadCta_BlockType {
                                    id
                                    phoneNumber {
                                        url
                                        customText
                                        text
                                    }
                                    email {
                                        url
                                        text
                                        customText
                                    }
                                    location
                                    downloadCta {
                                        url
                                        text
                                        type
                                    }
                                  }
                                }
                              }

                        }
                    }
                }
            }
        }
    }
`;