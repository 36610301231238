import { gql } from "apollo-boost";
// import blocks from "blocks/query";
import { blogImage, link, seomatic } from 'querypieces';

export default gql`
    query($uri: String) {
        entry(uri: [$uri]) {
            ...on blog_blog_Entry {
                articleType
                blogPostHeaderMatrix {
                  ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
                    id
                    header
                    date
                    publisher
                    authors
                  }
                }
                blogBuilderMatrix {
                    ... on blogBuilderMatrix_blogRichText_BlockType {
                        id
                        richText
                    }
                    ... on blogBuilderMatrix_blogImage_BlockType {
                        id
                        ${blogImage}
                        altText
                        caption
                    }
                    ... on blogBuilderMatrix_primaryCta_BlockType {
                        id
                        primaryCta {
                            ${link}
                        }
                    }
                }


            }

        }
        ${seomatic}
    }
`;

