import React from "react"
import Blocks from "blocks"
import styles from "./backgroundBlock.module.scss"

const BackgroundBlock = ({ colorSelect, children }) => {
  return <div className={`${styles.element} bg--${colorSelect} ${children.length > 3 ? "grid" : ""}`}>
    <Blocks blocks={children} />
  </div>
}

export default BackgroundBlock