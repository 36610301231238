import { idtype, featurePostImage, featureReportLinkTo } from "querypieces";

export default `
...on pageBuilder_featureReport_BlockType {
	${idtype}
	id
	altText
	plainText
	themePicker
	${featurePostImage}
	${featureReportLinkTo}
}
`