import React from "react"
import "./hero.scss"
import Image from "components/image";

const Hero = ({ heroImage, altText }) => {
  return (
    <div className={`heroImage`} id='heroImg'>
      <Image {...heroImage[0].optimized} title={heroImage[0].title} alt={altText} />
    </div>
  );
}

export default Hero