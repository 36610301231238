import React from "react"
import Link from "components/link";
import Image from "components/image";
import { ReactComponent as Bubble } from '../../../src/assets/bubble.svg';

import styles from "./related-articles.module.scss";

const RelatedArticleCard = ({
  id,
  className = '',
  singleCard,
  link,
  articleType,
  altText,
  articleImage,
  header,
  date,
  publisher,
  authors,
}) => {
  return (
    <div key={id} className={`${className} ${singleCard ? styles.singleCard : styles.articleCard}`}>
      <Link {...link}>
        <div className={styles.cardInner} >
          <div className={styles.cardTop}>
            <p>{articleType}</p>
          </div>
          <div className={`${styles.icon} ${articleType.toLowerCase()}`}>
            <Bubble />
          </div>
          <div className={styles.articleImageWrapper}>
            {articleImage[0] ? <Image className={styles.articleImg} alt={altText} title={articleImage[0].title} {...articleImage[0].optimized} /> : null}
          </div>
          <h3 className={styles.cardTitle}>{header}</h3>
          <div className={styles.filler}></div>
          {date ? <p className={styles.cardDate}>{date}</p> : null}
          {publisher ? <p className={styles.cardPublisher}>{publisher}</p> : null}
          {authors ? <p className={styles.cardAuthor}>{authors}</p> : null}
        </div>
      </Link>
    </div>
  );
}


export default RelatedArticleCard