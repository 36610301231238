// import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

export default (query, relatedTo, limit) => {
      
      const fetch = query;

      const { loading, error, data } = useQuery(fetch, {
            variables: {
                  relatedTo,
                  limit,
            },
            notifyOnNetworkStatusChange: true
      });

      return {
            loading,
            error,
            data
      };
};