import React, { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom";

import { a, useSpring } from "react-spring";

import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Minus } from "../../assets/minus.svg";

import "./leadership-team.scss"

const LeadershipTeam = ({ heading, plainText, body, children }) => {
  const [activeTab, setActiveTab] = useState('Leadership');

  return (
    <div className="leadershipWrapper" id="leadershipTeam" >
      <div className="leadershipInnerWrapper">
        <h1 className="leadershipHeader">{heading}</h1>
        <p className="leadershipBody">{body}</p>
        <div className="filterHeader">
          <ul className="tabTitles">
            {children.map(({ id, teamType, leadershipLink }) =>
              <li
                key={id}
                role="button"
                tabIndex="0"
                aria-pressed={teamType === activeTab ? true : false}
                className={teamType === activeTab ? `active tabTitle` : `tabTitle`}
                onClick={(e) => {
                  setActiveTab(teamType);
                  e && e.target && e.target.blur();
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setActiveTab(teamType);
                  }
                }}
              >
                <p className={"tabBtn"}>
                  <span>{teamType}</span>
                  <span>{activeTab === teamType ? <Minus /> : <Plus />}</span>
                </p>

                <AccordionItem isActive={activeTab === teamType}>
                  {leadershipLink.map(({ id, bioPageSwitch, fullName, positionTitle, uri }) =>
                    bioPageSwitch ?
                      <Link key={id} to={`/${uri}`}>
                        <div className="leadershipTile">
                          <div className="tileTop" >
                            <h3>{fullName}</h3>
                          </div>
                          <p>{positionTitle}</p>
                        </div>
                      </Link>
                      :
                      <div key={id} className="leadershipTile">
                        <div className="tileTop" >
                          <h3>{fullName}</h3>
                        </div>
                        <p>{positionTitle}</p>
                      </div>
                  )}
                </AccordionItem>

              </li>

            )}
          </ul>
        </div>
      </div>
    </div >
  );
}

const AccordionItem = ({ isActive, children }) => {
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const ref = useRef();

  useEffect(() => {
    const calcContentMaxHeight = () => {
      ref && ref.current && setContentMaxHeight(ref.current.scrollHeight);
    };

    calcContentMaxHeight();

    window.addEventListener("resize", () => calcContentMaxHeight());

    return () => window.removeEventListener("resize", calcContentMaxHeight());
  }, [ref, contentMaxHeight]);

  const { maxHeight } = useSpring({
    maxHeight: isActive ? `${contentMaxHeight}px` : "0px",
    config: { duration: 300 }
  })

  return <a.div
    className="tabContent"
    ref={ref}
    style={{ maxHeight }}
  >
    <div className={"tabInner"}>
      {children}
    </div>
  </a.div>
}

export default LeadershipTeam