import { gql } from "apollo-boost";
import { inlineImage } from "querypieces"

export default gql`
query {
  modal: globalSet (handle:"modal") {
    ...on modal_GlobalSet {
      heading
      simpleRichTextBody
      homepageOnly
      isEnabled
      image: ${inlineImage}
      centered
      cta {
        title
        text
        type
        url
        element {
          uri
        }
      }
    }
  }
}`;
