import React from "react"
import Image from "components/image";
import RelatedArticleCard from "blocks/related-articles/related-article-card";
import Link from "components/link";
import useIsMobile from "helpers/useIsMobile";
import { ReactComponent as Bubble } from '../../../src/assets/bubble.svg';

import "blocks/theme-picker.scss";
import styles from "./feature-post.module.scss"


const FeaturePost = ({ id, themePicker, altText, plainText, featurePostImage, linkTo }) => {
  const breakpoint = 992;
  const isMobile = useIsMobile(breakpoint);

  const { articleType, relatedArticleImage } = linkTo.element;
  const { date, authors, header, publisher } = linkTo.element.blogPostHeaderMatrix[0];

  return (
    isMobile ?
      <div className={`${themePicker} ${styles.mobileFeatureContainer}`}>
        <RelatedArticleCard
          link={linkTo}
          id={id}
          articleType={articleType}
          altText={altText}
          articleImage={relatedArticleImage}
          header={header}
          date={date}
          publisher={publisher}
          authors={authors}
          singleCard={true}
        />
      </div>

      :
      <div className={`${themePicker} ${styles.featureContainer}`}>
        <Link className={styles.featureLink} {...linkTo} >
          <Image alt={altText} title={featurePostImage[0].title} {...featurePostImage[0].optimized} />

          <div className={styles.blogContent}>
            <p className={styles.typeHandle}>{articleType}</p>
            <div className={`${styles.icon} ${articleType.toLowerCase()}`}>
              <Bubble />
            </div>
            <h1 className={styles.headline}>{header}</h1>
            {date || publisher ?
              <div className={styles.details}>
                {date ? <p className={styles.date}>{date}</p> : null}
                {publisher ? <p className={styles.publisher}>{publisher}</p> : null}
              </div>
              : null}
            {authors ?
              <div className={styles.authors}>
                <p> {authors} </p>
              </div>
              : null}
            <p className={styles.summary}>{plainText}</p>
            {/* <figure className={styles.featurePostArrow}><Arrow/></figure> */}
            <button className={styles.button}>Read Now</button>
          </div>
        </Link>
      </div>
  );
}

export default FeaturePost