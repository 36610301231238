import { gql } from "apollo-boost";
import { idtype, relatedArticleImage } from "querypieces";

export default gql`
    query($relatedTo:[QueryArgument], $limit:Int) {
        entries(section: "blog", relatedTo: $relatedTo, limit: $limit) {
            ${idtype}
            ... on blog_blog_Entry {
              id
              altText
              articleType
              ${relatedArticleImage}
              blogPostHeaderMatrix {
                ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
                  ${idtype}
                  header
                  publisher
                  date
                  authors
                }
              }
              uri
            }
            ... on blog_externalPost_Entry {
              ${idtype}
              altText
              articleType
              externalUrl {
                url
              }
              blogPostHeaderMatrix {
                ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
                  ${idtype}
                  date
                  authors
                  header
                  publisher
                }
              }
              ${relatedArticleImage}
            }
        }
    }
`;



