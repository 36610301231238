import React from "react";
import Link from "components/link";
import './button.scss';

const Button = ({ primary, theme, className, ...rest }) => {
  const parentStyle = className ? className : ``;

  return (
    <Link
      rel="noopener noreferrer"
      className={primary ? `primary ${theme} ${parentStyle}` : `secondary ${theme} ${parentStyle}`}
      {...rest}
    >
    </Link>
  );

}

export default Button;
