import React from "react";
import searchQuery from "./searchQuery";
import fetchSearchResults from "./fetchSearchResults";
import ReactHtmlParser from "react-html-parser";

import Sitemap from "components/sitemap"
import Link from "components/link";

import styles from "./search.module.scss"

const SearchResult = ({
  searchValue,
  noResultsText,
  siteMapText,
  siteMapTextNoResults,
  siteMap
}) => {

  const { data, fetchMore } = fetchSearchResults(searchQuery, searchValue, 0, 5);

  const parseHtmlString = (richText) => {

    // parse HTML string and only return <p> tag elements
    const parsed = ReactHtmlParser(richText).filter(elem => elem.type === 'p');

    // precautionary checks to prevent trying to access an array element that might not exist
    let text =
      (parsed.length > 0 &&
        parsed[0].props &&
        parsed[0].props.children.length > 0
      ) ?
        parsed[0].props.children
        : '';

    // grab child probs for different html elements from within in <p>
    let finalText = text.map(copy => {
      const array = copy.props && copy.props.children ? copy.props.children[0] : copy;
      return array.toString()
    })

    // Cutoff at 200 characters and add elipses
    if (finalText.length > 200) {
      text = finalText.substr(0, 200) + '...';
    }

    return text;
  };

  const seeMoreResults = () => {
    fetchMore({
      variables: {
        offset: data.entries.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  }

  const hasResults = data && data.entries.length > 0 && searchValue !== null

  return (
    <div className={styles.resultsWrapper}>
      {
        searchValue !== null ?
          data && data.entries.length > 0 ?
            data.entries.map((elem) => {

              let headline = elem.title;
              let subheader = '';
              let link = { url: '', type: '', element: { uri: '' } };

              switch (elem.typeHandle) {
                case "page":
                  // if a normal page entry
                  // grab header text on page to be in search result
                  // link to page
                  link.type = "entry";
                  link.element.uri = elem.uri;

                  const foundObj = elem.pageBuilder.find(e => e.__typename === 'pageBuilder_header_BlockType');
                  subheader = foundObj ? foundObj.heading : '';
                  break;
                case "blog":
                  link.type = "entry";
                  link.element.uri = elem.uri;
                  const blogPostRichTexts = elem.blogBuilderMatrix.filter(elem => elem.__typename === "blogBuilderMatrix_blogRichText_BlockType");

                  // if there is is a blog post rich text field on the blog post,
                  // use the first text block to get subheader text
                  if (blogPostRichTexts.length > 0) {
                    subheader = parseHtmlString(blogPostRichTexts[0].richText)
                  }
                  break;
                case "leadershipBio":
                  // if leadership bio, need to check if member has their own dedicated page
                  // if so, link to page and pull first 200chars of their bio
                  // else, just link to about page
                  if (elem.bioPageSwitch) {
                    link.type = "entry";
                    link.element.uri = elem.uri;
                    subheader = parseHtmlString(elem.blogPostRichText);
                  } else {
                    link.type = "entry";
                    link.element.uri = "about";
                  }
                  break;
                case "externalPost":
                  link = {
                    url: elem.externalUrl.url
                  }
                  break;
                case "news":
                case "contact":
                  link.type = "entry";
                  link.element.uri = elem.uri;
                  subheader = elem.heading;
                  break;
                case "reports":
                  link.type = "entry";
                  link.element.uri = elem.uri;
                  subheader = elem.plainText;
                  break;
                default:
                  return null;
              }

              return (
                <Link {...link} key={elem.id}>
                  <h3>{headline}</h3>
                  <p>{subheader}</p>
                </Link>
              );
            })
            :
            <h3 className={styles.noResultsText}>{noResultsText}</h3>
          : null
      }

      {searchValue !== null && data && (data.entries.length < data.entryCount) ?
        <button className={styles.seeMoreBtn} onClick={seeMoreResults}> See more </button>
        : null}

      <Sitemap data={siteMap} results={hasResults ? siteMapTextNoResults : siteMapText} />
    </div>
  );

}

export default SearchResult