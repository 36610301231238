import React from "react"
import styles from "./copyBlock.module.scss"
import RichText from "components/richText"

const CopyBlock = ({
  heading,
  richText
}) => {
  return <div className={styles.element}>
    {heading && <h2 className={styles.heading}>{heading}</h2>}
    {richText && <RichText richText={richText} textColor={"green"} />}
  </div>
}

export default CopyBlock