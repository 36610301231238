import React, { useContext, useEffect } from "react"
import styles from "./reports.module.scss";
import fetch from "../../fetch";
import query from "./query";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import Image from "components/image";
import { ReportNav } from "./reportNav";
import { AppContext } from "appContext";
import * as Scroll from 'react-scroll';

import FullPagePhotoMatrix from "blocks/full-page-photo-matrix";
import InlinePhotoMatrix from "blocks/inline-photo-matrix";
import Header from "blocks/header";
import RichText from "components/richText";
import Button from "components/button";

const Reports = ({ match }) => {
  const reportContext = useContext(AppContext);
  const navigationContext = useContext(AppContext);

  useEffect(() => {
    navigationContext.setIsReport(true);
    reportContext.setIsReport(true);
  }, [reportContext, navigationContext]);

  const uri = match.url.replace("/", "");
  const { data, loading, error } = fetch(query, uri);

  const ScrollElement = Scroll.Element;

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    console.log(error)
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  const { children, title, heroImage, altText, heroHeading, breadcrumb, heading, introBody, body, richText, cta } = data.entry;
  const hasImg = heroImage.length > 0;
  const hasHeading = breadcrumb || heading || introBody || richText || cta.element || cta.url;

  return (
    <React.Fragment>
      <ScrollElement name="top">
        <ReportNav title={title} children={children} />
      </ScrollElement>

      {
        hasImg && <header className={styles.reportHeader}>
          <div className={styles.reportHeroImg}>
            <Image {...heroImage[0]} {...heroImage[0].optimized} title={heroImage[0].title} alt={altText} />
          </div>
          <div className={styles.content}>
            <h1 className={styles.heroHeading}>{heroHeading}</h1>
          </div>
        </header>
      }
      {
        hasHeading && <section className={`${styles.reportIntroduction} ${!hasImg ? styles.reportIntroNoImage : ""}`}>
          <Header
            breadcrumb={breadcrumb}
            heading={heading}
            introBody={introBody}
            richText={body}
            cta={{}}
            isReport={true}
          />
          <div className={styles.container}>
            <RichText richText={richText} />
            <div className={styles.cta}>
              <Button {...cta} />
            </div>
          </div>
        </section>
      }
      {
        children.map(({ id, slug, reportBuilder }) => {

          return (
            <section key={id} className="reportSection">
              <ScrollElement name={slug}>
                {reportBuilder.map(({ typeHandle, id, ...rest }) => {

                  return (
                    typeHandle === "fullPagePhotoMatrix" ?
                      <FullPagePhotoMatrix key={id} {...rest} />
                      :
                      <InlinePhotoMatrix key={id} {...rest} />
                  );
                })}

              </ScrollElement>
            </section>
          );
        })
      }
    </React.Fragment>

  );
}

export default Reports