import { gql } from "apollo-boost";
import { inlineImage, seomatic } from 'querypieces';

export default gql`
    query($uri: String) {
        entry(uri: [$uri]) {
            ... on leadershipBio_leadershipBio_Entry {
                id
                fullName
                teamType
                positionTitle
                altText
                ${inlineImage}
                blogPostRichText
                relatedArticleSection {
                    id
                }
            }
        }
        ${seomatic}
    }
`;
