import React, { useState, useRef, useEffect } from "react"
import Image from "components/image";
import Button from "components/button";
import RichText from "components/richText";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Minus } from "../../assets/minus.svg";

import "./collapsable-accordion-matrix.scss"

const DEFAULT_HEIGHT = 82;

const CollapsableAccordionMatrix = ({ altText, heading, inlineImage, cta, children, photoBorderSwitch }) => {
  return (
    <div className="accordionMatrixWrapper">
      {inlineImage.length > 0 ?
        photoBorderSwitch ?
          <figure className="inlineImgBorder">
            {
              inlineImage[0].extension === "svg" ?
                <Image extension={inlineImage[0].extension} url={inlineImage[0].url} title={inlineImage[0].title} alt={altText} />
                : <Image {...inlineImage[0].optimized} title={inlineImage[0].title} alt={altText} />
            }
          </figure>
          :
          <figure className="inlineNoImgBorder">
            {
              inlineImage[0].extension === "svg" ?
                <Image extension={inlineImage[0].extension} url={inlineImage[0].url} alt={altText} title={inlineImage[0].title} />
                : <Image {...inlineImage[0].optimized} alt={altText} title={inlineImage[0].title} />
            }
          </figure>
        : null}

      {heading ? <h1 className="accordionHeading">{heading}</h1> : null}

      {children.map(({ id, ...rest }) =>
        <AccordionBlock key={id} {...rest} />
      )}
      {(cta.url || cta.element) ?
        <Button
          theme="blue"
          primary={true}
          className="accordionMatrixCta"
          {...cta}
        >
        </Button> : null}
    </div>
  );
}

const AccordionBlock = ({ body, subheader, accordionMatrix }) => {

  return (
    <div>
      {subheader ? <h3 className="accordionSubheader">{subheader}</h3> : null}
      {body ? <p className="accordionBody">{body}</p> : null}
      <div className="accordionBlock">
        {accordionMatrix.map(({ id, ...rest }) =>
          <AccordionItem key={id} firstOpen={false} {...rest} />
        )}
      </div>
    </div>
  );
}

export const AccordionItem = ({ bodyCopy, cost, programLength, programName, cta, firstOpen }) => {
  const [open, setOpen] = useState(firstOpen);
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const ref = useRef();
  const button = useRef();

  const updateHeight = (newHeight) => {
    let buttonHt = button.current ? button.current.getBoundingClientRect().height : DEFAULT_HEIGHT
    let newHt = !open && ref.current ? ref.current.getBoundingClientRect().height + buttonHt : buttonHt

    setHeight(newHeight ? newHt : buttonHt)
  }

  const toggleItem = () => {
    updateHeight(true)
    setOpen(!open)
  }

  useEffect(() => {
    window.addEventListener("resize", () => updateHeight(false))
    return () => window.removeEventListener("resize", () => updateHeight(false))
  })

  return (
    <div className={`accordionItem ${open ? `open` : 'closed'}`} style={{ height }}>
      <button onClick={toggleItem} className={open ? `open` : `closed`} ref={button}>
        <p className="title">{programName}</p>
        {open ? <figure className="accordionSvg"><Minus /></figure>
          : <figure className="accordionSvg"><Plus /></figure>}
      </button>
      <div className="contentWrapper" ref={ref}>
        {(cost || programLength) ?
          <div className="topRow">
            <p>{programLength}</p>
            <p>{cost}</p>
          </div>
          : null}
        {
          <RichText
            richText={bodyCopy}
            textColor="blue"
          />
        }
        {(cta && (cta.url || cta.element)) ?
          <Button
            theme="blue"
            primary={false}
            className="accordionBtn"
            {...cta}
          >
          </Button> : null}
      </div>
    </div>
  );
}

export default CollapsableAccordionMatrix