import { idtype } from "querypieces";

export default `
... on pageBuilder_leadershipTeam_BlockType {
	${idtype}
	heading
	body
	plainText
	children {
	  id
	  ... on pageBuilder_leadershipTab_BlockType {
		id
		teamType
		title
		leadershipLink {
		  ... on leadershipBio_leadershipBio_Entry {
			id
			fullName
			positionTitle
			bioPageSwitch
			uri
			url
		  }
		}
	  }
	}
  }
`