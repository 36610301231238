import React from "react";
import {
  Switch, Route, useLocation,
  Redirect
} from "react-router-dom";
import Page from "components/page";
import Blog from "components/blog";
import News from "components/news";
import Leadership from "components/leadership";
import Footer from "components/footer";
import Contact from "components/contact";
import EmailSuccess from "components/email-success";
import EmailError from "components/email-error";
import Search from "components/search";
import Reports from "components/reports";
import Navigation from "components/navigation";

import Loading from "components/loading";
import Error from "components/error";

import { AppProvider } from "appContext";
import fetch from "../../fetch";
import retourQuery from "./retourQuery.js";
import AlertBar from "components/alertBar";
import Modal from "components/modal";

const App = () => {
  //   ReactGA.initialize("UA-108073420-1");
  const uri = useLocation().pathname;
  const { data, loading, error } = fetch(retourQuery, uri);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    console.log(error)
    return <Error />;
  }
  const regex = new RegExp('https://|http://');

  return (
    <AppProvider>
      <Modal location={uri} />
      <Navigation />
      <AlertBar location={uri} />
      <Switch>
        {data && data.retour && data.retour.id ?
          // if redirecting to external URL that fits regex pattern of having https:// or http://
          // then send to that location otherwise redirect internally
          regex.test(data.retour.redirectDestUrl) ?
            <Route path={data.retour.redirectSrcUrl} component={() => {
              window.location.replace(data.retour.redirectDestUrl);
              return null;
            }} />
            :
            <Redirect from={data.retour.redirectSrcUrl} to={data.retour.redirectDestUrl} status={data.retour.redirectHttpCode} />
          : null}

        <Route path="/news/:slug" component={Blog} />
        <Route path="/leadership/:slug" component={Leadership} />

        <Route path="/news" component={News} />

        <Route path="/email-signup-success" component={EmailSuccess} />
        <Route path="/email-signup-error" component={EmailError} />

        <Route path="/contact" component={Contact} />

        <Route path="/search" component={Search} />

        <Route path="/reports/:slug" component={Reports} />

        <Route path="/:slug/:slug/:slug" render={({ match: { url } }) =>
          <Route path={`${url}/:slug?`} component={Page} />
        } />

        <Route path="/:slug/:slug" render={({ match: { url } }) =>
          <Route path={`${url}/:slug?`} component={Page} />
        } />

        <Route path="/:slug" render={({ match: { url } }) =>
          <Route path={`${url}/:slug?`} component={Page} />
        } />

        <Route path="/:slug?" component={Page} />

      </Switch>
      <Footer />
    </AppProvider>
  );
};

export default App;
