import { idtype, featurePostImage, featurePageLinkTo } from "querypieces";

export default `
...on pageBuilder_featureCampaign_BlockType {
	${idtype}
	id
	altText
	plainText
	themePicker
	${featurePostImage}
	${featurePageLinkTo}
}
`