import React, { useEffect, useContext } from "react"
import "./leadership.scss"
import fetch from "../../fetch";
import query from "./query";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import Image from "components/image";
import RichText from "components/richText";
import Link from "components/link";
import { ReactComponent as Arrow } from "assets/big_arrow.svg";
import Seo from "components/seo";

import RelatedArticles from "blocks/related-articles";
import { AppContext } from "appContext";

const Leadership = ({ match }) => {
  const uri = match.url.replace("/", "");
  const { data, loading, error } = fetch(query, uri);
  const navigationContext = useContext(AppContext);

  useEffect(() => {
    navigationContext.setIsReport(false);
  }, [data, navigationContext]);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    console.log(error)
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  const { fullName, positionTitle, teamType, inlineImage, blogPostRichText, relatedArticleSection, altText } = data.entry;

  const showRelatedArticles = relatedArticleSection.length > 0;

  const linkElement = {
    element: {
      uri: "about/leadership"
    }
  };

  return (
    <React.Fragment>
      <Seo seomaticData={data.seomatic} />
      <div className="leadershipContentWrapper">
        <header className="leadershipHead">
          <div>
            <Link
              type="entry"
              className="leadershipBreadcrumb"
              {...linkElement}
            >
              <Arrow />
              <span className="leadershipTeamType">{teamType}</span>
            </Link>
            <h1 className="leadershipName">{fullName}</h1>
            <h3 className="leadershipTitle">{positionTitle}</h3>
          </div>
          <figure className="leadershipInlineImage">
            <Image alt={altText} title={inlineImage[0].title} {...inlineImage[0].optimized} />
          </figure>
        </header>

        <div className="leadershipBodyCopy">
          <RichText
            richText={blogPostRichText}
            textColor="blue"
          />
        </div>
      </div>

      {showRelatedArticles ?
        <RelatedArticles
          subheader="RELATED ARTICLES"
          tags={relatedArticleSection}
          limit={3}
        />
        : null}
    </React.Fragment>
  );
}

export default Leadership