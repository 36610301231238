import { gql } from "apollo-boost";

export default gql`
query MyQuery($search:String, $offset: Int, $limit: Int, $section: [String] = ["blog", "contact", "leadershipBio", "news", "pages", "reports"]) {
    entries(search: $search, limit: $limit, offset: $offset, orderBy: "score", section: $section) {
      id
      ... on pages_page_Entry {
        id
        typeHandle
        title
        uri
        pageBuilder {
          ... on pageBuilder_header_BlockType {
            id
            heading
          }
        }
      }
      ... on blog_blog_Entry {
        id
        typeHandle
        title
        uri
        blogBuilderMatrix {
          ... on blogBuilderMatrix_blogRichText_BlockType {
            id
            richText
          }
        }
      }
      ... on blog_externalPost_Entry {
        id
        typeHandle
        title
        externalUrl {
          type
          url
        }
      }
      ... on leadershipBio_leadershipBio_Entry {
        id
        typeHandle
        title
        uri
        bioPageSwitch
        blogPostRichText
        positionTitle
      }
      ... on contact_contact_Entry {
        id
        typeHandle
        heading
        title
        uri
      }
      ... on news_news_Entry {
        id
        typeHandle
        heading
        uri
        title
      }
      ... on reports_reports_Entry {
        id
        typeHandle
        heading
        uri
        title
        plainText
      }
    }
    entryCount(search: $search, section: $section)
  }

`;