import React from "react";
import Link from "components/link";
import Loading from "components/loading";
import styles from "./footer.module.scss"
import query from "./query.js";
import { ReactComponent as WhiteLogo } from '../../../src/assets/fh_logo_white.svg';
import { ReactComponent as FacebookLogo } from '../../../src/assets/facebook.svg';
import { ReactComponent as IGLogo } from '../../../src/assets/instagram.svg';
import { ReactComponent as TwitterLogo } from '../../../src/assets/twitter.svg';
import { ReactComponent as LinkedInLogo } from '../../../src/assets/linkedIn.svg';
import useIsMobile from "helpers/useIsMobile";

import { useQuery } from "@apollo/react-hooks";

const Footer = () => {

  const { loading, data, error } = useQuery(query);

  // breakpoint matches media breakpoint in footer.module.scss
  const breakpoint = 992;
  const isMobile = useIsMobile(breakpoint);

  /*
      Pardot email forms (which the client uses) are weird, they don't accept AJAX requests and instead send back
      success or failure URLs to redirect the user to either a success or failure location. To get around re-routing the user to a new page
      every time they subscribe to the email list, the email form handler is embedded in an iframe in the footer. The success / failure pages are simple
      text pages which are then served back in that iframe, simulating a success or failure message.

  */
  const formHtml = `
    <!DOCTYPE html>
    <html>
    <head>
      <link rel="stylesheet" href="https://use.typekit.net/zwr6ikj.css">
      <meta charset=&quot;utf-8&quot;>
      <style type="text/css">
        body {
          margin: 0px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: "adelle-sans", sans-serif;
        }

        .wForm {
          color: #FFFFFF;
          padding-right: 16px;
        }

        .wForm label {
          display: none;
        }

        .wFormWebPage {
          color: #FFFFFF;
        }

        .wFormContainer {
          color: #FFFFFF;
          margin: 0px;
          font-size: 16px;
          line-height: 26px;
          font-family: "adelle-sans", sans-serif;
        }

        .wFormTitle {
          margin-top: 0;
          font-size: 32px;
          font-weight: 600;
          line-height: 35px;
          font-family: "adelle-sans", sans-serif;
          width: 60%;
        }

        #tfa_3-D,
        #tfa_2-D {
          display: inline-block;
          width: calc(50% - 2px);
        }

        .inputWrapper input[type="text"] {
          width: calc(100% - 2px);

          box-sizing: border-box;
          background-color: transparent;
          border: 2px solid #93C13E;
          color: #FFFFFF;
          border-radius: 10px;
          height: 48px;
          padding: 0px 0px 0px 16px;
          font-size: 16px;
          line-height: 26px;
          font-family: "adelle-sans", sans-serif;
        }

        .inputWrapper input[type="text"]:focus {
          outline-offset: 10px;
          input::placeholder {
            color: #002B00;
          }
        }

        .inputWrapper input[type="text"]:focus-visible {
          background-color: #002B00;
          outline: none;

          input::placeholder {
            color: #002B00;
          }
        }

        .inputWrapper select {
          box-sizing: border-box;
          background-color: #001F00;
          color: #FFFFFF;
          border-radius: 20px;
          border: 0;
          font-size: 16px;
          line-height: 26px;
          font-family: "adelle-sans", sans-serif;
          width: 100%;
        }

        input[type="submit"] {
          cursor: pointer;
          background-color: transparent;
          border: 2px solid #93C13E;
          border-radius: 30px;
          color: #FFFFFF;
          height: 48px;
          width: 112px;
          padding: 0px;
          margin-top: 12px;
          font-size: 16px;
          line-height: 26px;
          font-family: "adelle-sans", sans-serif;
          transition: all .3s;
        }

        input[type="submit"]:hover {
          background-color: #93C13E;
          border-color: #93C13E;
          color: #002B00;
        }

        input[type="submit"]:focus {
            outline-offset: 10px;
        }

        input[type="submit"]:focus-visible {
            outline-color: #ffffff;
            outline-offset: 12px;
            outline-style: solid;
            outline-width: 1px;
        }

        #tfa_1-D {
          margin-top: 5px;
        }

        input::placeholder {
          color: white;
        }

        .wFormFooter {
          display: none;
        }

        .supportInfo {
          margin: 0;
        }

        </style>
    </head>

    <body>
    <!-- FORM: BODY SECTION -->
    <div class="wFormContainer" >
      <div class="wFormHeader"></div>
      <div class=""><div class="wForm" id="5015261-WRPR" dir="ltr">
        <div class="codesection" id="code-5015261"></div>
        <h3 class="wFormTitle" data-testid="form-title" id="5015261-T">Get the latest from Fountain House</h3>
        <form method="post" action="https://www.tfaforms.com/api_v2/workflow/processor" class=" labelsLeftAligned" id="5015261" role="form">
        <div class="oneField field-container-D    " id="tfa_2-D">
        <label id="tfa_2-L" class="label preField reqMark" for="tfa_2">First Name</label><div class="inputWrapper">
          <input required aria-required="true" type="text" id="tfa_2" name="tfa_2" value="" title="First Name" placeholder="First Name" class="required"></div>
        </div>
        <div class="oneField field-container-D    " id="tfa_3-D">
        <label id="tfa_3-L" class="label preField reqMark" for="tfa_3">Last Name</label><div class="inputWrapper">
          <input required aria-required="true" type="text" id="tfa_3" name="tfa_3" value="" title="Last Name" placeholder="Last Name" class="required"></div>
        </div>
        <div class="oneField field-container-D    " id="tfa_1-D">
        <label id="tfa_1-L" class="label preField reqMark" for="tfa_1">Email</label><div class="inputWrapper">
          <input required aria-required="true" type="text" id="tfa_1" name="tfa_1" value="" title="Email" placeholder="Email" class="required"></div>
        </div>
        <div class="oneField field-container-D     wf-acl-hidden" id="tfa_6-D" style="display:none;">
        <label id="tfa_6-L" class="label preField " for="tfa_6">Subscriptions Email</label><div class="inputWrapper"><select id="tfa_6" multiple name="tfa_6[]" title="Subscriptions Email" class=""><option value="">Please select...</option>
        <option value="tfa_7" id="tfa_7" class="" selected data-default-value="true">Advocacy Action Alert</option>
        <option value="tfa_8" id="tfa_8" class="" selected data-default-value="true">College Re-Entry</option>
        <option value="tfa_9" id="tfa_9" class="" selected data-default-value="true">Fountain Gallery</option>
        <option value="tfa_10" id="tfa_10" class="" selected data-default-value="true">Monthly eNews</option>
        <option value="tfa_11" id="tfa_11" class="" selected data-default-value="true">Special Events</option></select></div>
        </div>
        <div class="actions" id="5015261-A"><input type="submit" data-label="Submit" class="primaryAction" id="submit_button" value="Submit"></div>
        <div style="clear:both"></div>
        <input type="hidden" value="5015261" name="tfa_dbFormId" id="tfa_dbFormId"><input type="hidden" value="" name="tfa_dbResponseId" id="tfa_dbResponseId"><input type="hidden" value="95f81562dd47f474381f61e90f55f4e3" name="tfa_dbControl" id="tfa_dbControl"><input type="hidden" value="" name="tfa_dbWorkflowSessionUuid" id="tfa_dbWorkflowSessionUuid"><input type="hidden" value="5" name="tfa_dbVersionId" id="tfa_dbVersionId"><input type="hidden" value="" name="tfa_switchedoff" id="tfa_switchedoff">
        </form>
        </div></div><div class="wFormFooter"><p class="supportInfo"><a target="new" class="contactInfoLink" href="https://www.fountainhouse.org/" data-testid="contact-info-link"  style="display:none;">Fountain House Website</a><br></p></div>
          <p class="supportInfo" ></p>
        </div>
    </body>
    </html>
  `

  if (loading) {
    return <Loading />;
  }

  if (error) {
    console.log('error: ', error);
    return null;
  }

  const { officeName, streetAddress, cityStateZipcode, logoImageBlock, embed } = data.globalSet;
  const { facebook, instagram, youtube, twitter } = data.globalSet.socialMediaUrls[0];

  return (
    <footer>
      <div className={styles.footerTop}>
        <div className={styles.newsLetterSection}>
          <iframe
            style={{ minHeight: isMobile ? 350 : 270 }}
            srcDoc={formHtml}
            title="Email Signup Form Field"
            name="Email Signup Form Field"
            className={styles.newsLetterOutputFrame}
          >
          </iframe>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.footerSection}>
          <a href="/" className="logo" aria-label="Fountain House Logo">
            <WhiteLogo className={styles.footerLogo} />
          </a>
          {(officeName || cityStateZipcode) &&
            <div className={styles.contactInfo}>
              <p className={styles.officeName}>{officeName}</p>
              <div className={styles.addressBlock}>
                <p>{streetAddress}</p>
                <p>{cityStateZipcode}</p>
              </div>

            </div>
          }
        </div>
        {logoImageBlock && logoImageBlock.length > 0 &&
          <div className={styles.donateLogoSection}>
            {
              logoImageBlock.map((item) => {
                if (item.logo.length === 0) {
                  return null;
                }

                if (item.externalLink.url) {
                  return <Link key={item.id} {...item.externalLink} title={`Go to ${item.externalLink.url}`}>
                    <img
                      alt={item.altText}
                      src={item.logo[0].url}
                    />
                  </Link>
                }

                return (
                  <img
                    key={item.id}
                    alt={item.altText}
                    src={item.logo[0].url}
                  />
                );
              })
            }
          </div>
        }
        <div className={styles.footerLinks}>
          {data.globalSet.footerLinks.map(({ id, linkTo }) => {
            return (
              <Link key={id} className={styles.linkItem} {...linkTo} />
            );
          })}
        </div>
        {isMobile ?
          <div className={styles.mediaGrid}>
            <Link url={twitter.url} title="Go to FH's Twitter"><TwitterLogo alt="Twitter Logo" /></Link>
            <Link url={instagram.url} title="Go to FH's Instagram"><IGLogo alt="Instagram Logo" /></Link>
            <Link url={facebook.url} title="Go to FH's Facebook"><FacebookLogo alt="Facebook Logo" /></Link>
            <Link url={youtube.url} title="Go to FH's LinkedIn"><LinkedInLogo alt="LinkedIn Logo" /></Link>
          </div>
          :
          <div className={styles.mediaLinks}>
            <div className={styles.mediaRow}>
              <Link url={twitter.url} title="Go to FH's Twitter"><TwitterLogo alt="Twitter Logo" /></Link>
              <Link url={instagram.url} title="Go to FH's Instagram"><IGLogo alt="Instagram Logo" /></Link>
            </div>
            <div className={styles.mediaRow}>
              <Link url={facebook.url} title="Go to FH's Facebook"><FacebookLogo alt="Facebook Logo" /></Link>
              <Link url={youtube.url} title="Go to FH's LinkedIn"><LinkedInLogo alt="LinkedIn Logo" /></Link>
            </div>
          </div>
        }
      </div>

      {embed && <div dangerouslySetInnerHTML={{ __html: embed }} />}
    </footer>
  );
}

export default Footer