import React, { useEffect, useContext } from "react";
import query from "./query";
import fetch from "../../fetch";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import Seo from "components/seo";
import ContactBlock from "blocks/contact-block.js";

import { AppContext } from "appContext";

import styles from "./contact.module.scss";

const Contact = ({ match }) => {

  const uri = match.url.replace("/", "");
  const { data, loading, error } = fetch(query, uri);

  const navigationContext = useContext(AppContext);

  useEffect(() => {
    navigationContext.setIsReport(false);
  }, [data, navigationContext]);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    console.log(error)
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  const { heading, introBody, contactBlock } = data.entry;

  return (
    <React.Fragment>
      <Seo seomaticData={data.seomatic} />
      <div className={styles.contactPageWrapper}>
        <h1>{heading}</h1>
        <p className={styles.contactPageIntroBody}>{introBody}</p>

        <div className={styles.contactBlocksWrapper}>
          {contactBlock.map(({ id, ...rest }) => {
            return (
              <ContactBlock key={id} {...rest} />
            );
          }
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contact