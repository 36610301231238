import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import query from "./query.js";
import Button from "components/button";
import RichText from "components/richText";
import Image from "components/image"
// import { ReactComponent as Arrow } from "../../assets/fh_arrow.svg";
import styles from "./modal.module.scss";
import Link from "components/link";

const ESCAPE_KEYS = ['27', 'Escape'];

const Modal = ({ location }) => {
  const [visible, setVisible] = useState(false)
  const [open, setOpen] = useState(false);
  const [visited, setVisited] = useState(false);
  const hasVisitedBefore = sessionStorage.getItem('hasVisitedBefore');

  const closeModal = () => {
    setOpen(false);
    if (!hasVisitedBefore) {
      setVisited(true)
      sessionStorage.setItem('hasVisitedBefore', true);
    }
  };

  useEffect(() => {
    setVisited(hasVisitedBefore)
  }, [hasVisitedBefore, setVisited])

  const { loading, error, data } = useQuery(query);
  let homepage = location === "/"

  useEffect(() => {
    const { isEnabled, homepageOnly } = data ? data.modal : {};
    setVisible((isEnabled && homepage && homepageOnly) || (isEnabled && !homepageOnly) ? true : false)
  }, [data, homepage])

  if (loading || error) {
    return null;
  }

  const { cta, centered } = data.modal;

  return <LightBox active={visible} setOpen={setOpen} open={open} closeModal={closeModal} visited={visited}>
    <aside className={styles.modal} role="alertdialog" aria-modal="true" aria-labelledby="dialog_label" aria-describedby="dialog_desc">
      <span className={styles.bg} onClick={closeModal} />
      <div className={styles.frame}>
        <div className={styles.inner}>
          <button
            onClick={closeModal}
            className={styles.close}>
            Close
          </button>
          {cta ? <Link {...cta}>
            <ModalContent {...data.modal} />
          </Link> : <ModalContent {...data.modal} />}
          <div className={centered ? styles.center : ""}>
            {cta && <Button className={"secondary dark-blue"} {...cta} />}
          </div>
        </div>
      </div>
    </aside>
  </LightBox>
}

const ModalContent = ({
  image,
  heading,
  simpleRichTextBody
}) => {
  const hasImg = image.length > 0;
  return <div className={styles.content}>
    {hasImg &&
      <Image className={styles.image} {...image[0].optimized} />
    }
    {heading && <h2 className={styles.heading}>{heading}</h2>}
    {simpleRichTextBody && <RichText className={styles.body} richText={simpleRichTextBody} />}
  </div>
}

const LightBox = ({
  active,
  open,
  setOpen,
  closeModal,
  visited,
  children
}) => {
  // close modal when escape key is pressed
  const handler = ({ key }) => {
    if (ESCAPE_KEYS.includes(String(key))) {
      closeModal();
    }
  }
  useEffect(() => {
    setOpen(active)
  }, [active, setOpen])

  useEffect(() => {
    if (open && !visited) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open, visited]);

  // event listeners for escape key
  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    }
  });

  return open && !visited ? children : null;
}

export default Modal